<template>
  <layout-page>
    <template v-slot:toolbar-title>
      <v-toolbar-title>
        {{translate('Schemas', 'collection.schemas')}}
      </v-toolbar-title>
    </template>

    <v-card>
      <v-card-text>
        <v-data-table
          :loading="schemaData.loading"
          :items="schemaData.schemas"
          :headers="headers">
          <template v-slot:item.edit-schema-link="{item, value}">
            <v-btn
              :to="`/schemas/${item.collectionId}`"
              text>
              <v-icon>settings</v-icon> {{translate('Schema')}}
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </layout-page>
</template>
<script>
import {mapGetters} from 'vuex'
import LayoutPage from '@/components/layout/layout-page.vue'

export default {
  components: {
    LayoutPage
  },
  computed: {
    ...mapGetters(['translate', 'api', 'collectionsConfig']),
    headers () {
      return [{
        text: this.translate('Id', 'collectionId'),
        value: 'collectionId',
        sortable: true
      }, {
        text: this.translate('Label', 'label'),
        value: 'label',
        sortable: true
      }, {
        text: '',
        value: 'edit-schema-link',
        sortable: false
      }]
    }
  },
  asyncComputed: {
    schemaData: {
      async get () {
        let schemas = await this.api.collections.getCollectionSchemas()
        return {
          loading: false,
          schemas: this.collectionsConfig.mapCollectionSchemas(schemas)
        }
      },
      default: {
        loading: true
      }
    }
  },
  methods: {
  }
}
</script>
